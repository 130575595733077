import gql from 'graphql-tag'

export const UserFieldsFragment = gql`
  fragment UserFields on User {
    id
    email
    firstname
    lastname
    preferences {
      dateFormat
    }
    account {
      id
      name
    }
  }
`
