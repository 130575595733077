/*
 * General utils for managing cookies in Typescript.
 * Based on https://gist.github.com/joduplessis/7b3b4340353760e945f972a69e855d11
 */
export function setCookie(
  name: string,
  val: string,
  expirationDays: number = 7
) {
  const value = val
  const date = new Date()
  // Set expire
  date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000)
  // Set cookie
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`
}

export function getCookie(name: string) {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')

  if (parts && parts.length === 2) {
    return parts
      .pop()!
      .split(';')
      .shift()
  }
}

export function deleteCookie(name: string) {
  const date = new Date()
  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)
  // Set it
  document.cookie = `${name}=; expires=${date.toUTCString()}; path=/`
}
