/**
 * Created by mh on 2018/12/4.
 * Based on From: https://github.com/tobiasahlin/SpinKit
 */
import React from 'react'
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    dmcube_grid: {
      width: '33px', // Choose a multiple of 3
      height: '33px',
      margin: '60px auto',
    },
    dmcube: {
      width: '33.3333%',
      height: '33.3333%',
      backgroundColor: theme.palette.primary.main,
      float: 'left',
      animation: 'dmloader-cubeGridScaleDelay 1.3s infinite ease-in-out',
    },
    /*
     * Spinner positions
     * 1 2 3
     * 4 5 6
     * 7 8 9
     */
    dmloaderCube1: { animationDelay: '0.2s' },
    dmloaderCube2: { animationDelay: '0.3s' },
    dmloaderCube3: { animationDelay: '0.4s' },
    dmloaderCube4: { animationDelay: '0.1s' },
    dmloaderCube5: { animationDelay: '0.2s' },
    dmloaderCube6: { animationDelay: '0.3s' },
    dmloaderCube7: { animationDelay: '0.0s' },
    dmloaderCube8: { animationDelay: '0.1s' },
    dmloaderCube9: { animationDelay: '0.2s' },
    '@keyframes dmloader-cubeGridScaleDelay': {
      '0%, 70%, 100%': { transform: 'scale3D(1.0, 1.0, 1.0)' },
      '35%': { transform: 'scale3D(0.0, 0.0, 1.0)' },
    },
  })

interface IProps extends WithStyles<typeof styles> {}
const Spinner: React.FunctionComponent<IProps> = props => {
  const { classes } = props
  return (
    <div className={classes.dmcube_grid}>
      <div className={`${classes.dmcube} ${classes.dmloaderCube1}`} />
      <div className={`${classes.dmcube} ${classes.dmloaderCube2}`} />
      <div className={`${classes.dmcube} ${classes.dmloaderCube3}`} />
      <div className={`${classes.dmcube} ${classes.dmloaderCube4}`} />
      <div className={`${classes.dmcube} ${classes.dmloaderCube5}`} />
      <div className={`${classes.dmcube} ${classes.dmloaderCube6}`} />
      <div className={`${classes.dmcube} ${classes.dmloaderCube7}`} />
      <div className={`${classes.dmcube} ${classes.dmloaderCube8}`} />
      <div className={`${classes.dmcube} ${classes.dmloaderCube9}`} />
    </div>
  )
}

export default withStyles(styles)(Spinner)
