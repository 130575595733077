import * as React from 'react'
const { createContext, useState } = React
import { setCookie, getCookie } from './utils/cookie-utils'

import gql from 'graphql-tag'
import { ApolloProvider } from 'react-apollo-hooks'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
// TODO import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { getMe } from './__generated__/getMe'
import { loginUser } from './__generated__/loginUser'
import { logoutUser } from './__generated__/logoutUser'
import { UserFields } from './graphql/fragments/__generated__/UserFields'
import { UserFieldsFragment } from './graphql/fragments/UserFields.fragment'

const API_BASE_PATH = '/graphql'

const httpLink = new HttpLink({
  uri: API_BASE_PATH,
  credentials: 'same-origin',
})

// const getAuthToken = () => {
//   return localStorage.getItem('token') || null
// }
// const setAuthToken = (token: string) => {
//   return localStorage.setItem('token', token)
// }

// Auth Middleware if using Token Authorization in LocalStorage
// const authMiddleware = new ApolloLink((operation, forward) => {
//   // add the authorization to the headers
//   const authToken = getAuthToken()
//   if (authToken) {
//     operation.setContext(({ headers = {} }) => ({
//       headers: {
//         ...headers,
//         authorization: authToken,
//       },
//     }))
//   }

//   if (forward) return forward(operation)
//   else return null
// })

const client = new ApolloClient({
  link: ApolloLink.from([
    // authMiddleware,
    // otherMiddleware,
    httpLink,
  ]),
  cache: new InMemoryCache(),
})

const LOGIN_USER = gql`
  mutation loginUser($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragment}
`
const LOGOUT_USER = gql`
  mutation logoutUser {
    logout
  }
`
const USER_ME = gql`
  query getMe {
    me {
      ...UserFields
    }
  }
  ${UserFieldsFragment}
`

const anonymousUser: UserFields = {
  __typename: 'User',
  firstname: 'John',
  lastname: 'Doe',
  email: 'john.doe@deliver.media',
  id: '',
  preferences: { __typename: 'UserPreferences', dateFormat: 'DD.MM.YYYY' },
  account: { __typename: 'Account', id: '', name: 'deliver.media' },
}
const defaultValues = {
  authState: {
    authFailed: false,
    authFailedMessage: '',

    isAuthenticated: false,
    token: '',
  },
  userState: anonymousUser,
  authActions: {
    login: (email: string, password: string) => {},
    logout: () => {},
    restoreUser: () => {},
  },
}

const ApiContext = createContext(defaultValues)
export default ApiContext

export const ApiContextConsumer = ApiContext.Consumer

export const ApiContextProvider = ({ children }: any) => {
  const [authState, setAuthState] = useState(defaultValues.authState)
  const [userState, setUserState] = useState(defaultValues.userState)

  async function restoreUser() {
    try {
      const locked = getCookie('userlocked')
      if (locked === '1') {
        // Account is locked thus do not reauth
        setAuthState({ ...authState, isAuthenticated: false })
        return
      } else {
        const meData = await client.query<getMe>({ query: USER_ME })
        if (meData.data) {
          const userData = meData.data.me
          if (userData) {
            setUserState(userData)
            setAuthState({ ...authState, isAuthenticated: true })
          }
        }
      }
    } catch (err) {
      setAuthState({ ...authState, isAuthenticated: false })
    }
  }

  async function login(email: string, password: string) {
    try {
      // Reset state
      setCookie('userlocked', '0', -1)
      setAuthState({ ...authState, authFailedMessage: '', authFailed: false })
      // Send Auth Request
      const auth = await client.mutate<loginUser>({
        mutation: LOGIN_USER,
        variables: { email, password },
      })
      if (auth.data) {
        const userData = auth.data.login.user
        if (userData) {
          setUserState(userData)
          setAuthState({ ...authState, isAuthenticated: true })
        }
      }
    } catch (err) {
      const message = err.message
      setAuthState({
        ...authState,
        authFailed: true,
        authFailedMessage: message,
      })
    }
  }

  async function logout() {
    const loggedout = await client.mutate<logoutUser>({ mutation: LOGOUT_USER })
    if (loggedout.data) {
      if (loggedout.data.logout) {
        // Successfully logged out
        setCookie('userlocked', '1', 5585) // Set lock to avoir auto logon
        setAuthState({ ...authState, isAuthenticated: false })
      }
    }
  }

  const authActions = {
    login,
    logout,
    restoreUser,
  }

  return (
    <ApiContext.Provider
      value={{
        authState,
        authActions,
        userState,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ApiContext.Provider>
  )
}
