import * as React from 'react'
const { createContext, useState } = React
import { setCookie, getCookie } from './utils/cookie-utils'
import { createMuiTheme, Theme, MuiThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'

const primaryDefaultColor = '#5368c1'
const secondaryDefaultColor = '#eee'
const typeCookieName = 'dmp_palette_type'
const primaryColorCookieName = 'dmp_palette_color'
const secondaryColorCookieName = 'dmp_palette_color2'

const defaultValues = {
  themeState: {
    type: getCookie(typeCookieName) || 'light',
    primaryColor: getCookie(primaryColorCookieName) || primaryDefaultColor,
    secondaryColor:
      getCookie(secondaryColorCookieName) || secondaryDefaultColor,
  },
  themeActions: {
    toggleTheme: () => {},
    setColor: (color: string) => {},
    getTheme: () => createMuiTheme(),
  },
}

const ThemeContext = createContext(defaultValues)
export default ThemeContext

export const ThemeConsumer = ThemeContext.Consumer

export const ThemeProvider = ({ children }: any) => {
  const [themeState, setThemeState] = useState(defaultValues.themeState)

  function toggleTheme() {
    const type = themeState.type === 'dark' ? 'light' : 'dark'
    setThemeState({ ...themeState, type })
    setCookie(typeCookieName, type, 10 * 365) // Set for 10 years
  }

  function setColor(color: string) {
    let primaryColor = color
    // Abort if not a hex color
    if (!/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(primaryColor)) {
      primaryColor = primaryDefaultColor
    }
    setThemeState({ ...themeState, primaryColor })
    setCookie(primaryColorCookieName, primaryColor, 10 * 365) // Set for 10 years
  }

  function getTheme(): Theme {
    return createMuiTheme({
      // direction: 'rtl',
      palette: {
        type: themeState.type === 'dark' ? 'dark' : 'light',
        primary: { main: themeState.primaryColor },
        secondary: { main: themeState.secondaryColor },
      },
      typography: {
        useNextVariants: true,
      },
    })
  }

  const themeActions = {
    toggleTheme,
    setColor,
    getTheme,
  }

  return (
    <ThemeContext.Provider
      value={{
        themeState,
        themeActions,
      }}
    >
      <MuiThemeProvider theme={themeActions.getTheme()}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {children}
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}
