import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ThemeProvider } from './ThemeProvider'
import { ApiContextProvider, ApiContextConsumer } from './ApiProvider'

const MainApplication = React.lazy(() => import('./MainApplication'))
const SignInApplication = React.lazy(() => import('./SignInApplication'))

import BoxesSpinner from './utils/BoxesSpinner'

const App: React.FunctionComponent = () => {
  return (
    // Enable StrictMode to check for deprecation. Be aware, dependencies will show up as well
    // <React.StrictMode>
    <ThemeProvider>
      <ApiContextProvider>
        <BrowserRouter>
          <ApiContextConsumer>
            {api => (
              <div>
                {api.authState.isAuthenticated ? (
                  <Suspense fallback={<BoxesSpinner />}>
                    <MainApplication />
                  </Suspense>
                ) : (
                  <Suspense fallback={<BoxesSpinner />}>
                    <SignInApplication />
                  </Suspense>
                )}
              </div>
            )}
          </ApiContextConsumer>
        </BrowserRouter>
      </ApiContextProvider>
    </ThemeProvider>
    // </React.StrictMode>
  )
}

export default App
